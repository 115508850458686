import posthog from 'posthog-js'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const posthogClient = posthog.init(runtimeConfig.public.POSTHOG_KEY, {
    autocapture: false,
    api_host: runtimeConfig.public.POSTHOG_HOST,
    person_profiles: 'identified_only',
    capture_pageview: false // set this to false since we manually capture pageviews in router.afterEach
  })

  const router = useRouter()
  router.afterEach((to) => {
    nextTick(() => {
      posthog.capture('$pageview', {
        current_url: to.fullPath
      })
    })
  })

  return {
    provide: {
      posthog: () => posthogClient
    }
  }
})
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91city_93_45_91id_93_46htmldEuq7gvUfmMeta } from "/app/pages/[city]-[id].html.vue?macro=true";
import { default as _91hotel_93_45_91id_93_46htmlkUnRIcBpgmMeta } from "/app/pages/[hotel]-[id].html.vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "city-id.html___es",
    path: "/es/:city-:id(\\d+[dn]).html",
    meta: _91city_93_45_91id_93_46htmldEuq7gvUfmMeta || {},
    component: () => import("/app/pages/[city]-[id].html.vue").then(m => m.default || m)
  },
  {
    name: "city-id.html___en",
    path: "/en/:city-:id(\\d+[dn]).html",
    meta: _91city_93_45_91id_93_46htmldEuq7gvUfmMeta || {},
    component: () => import("/app/pages/[city]-[id].html.vue").then(m => m.default || m)
  },
  {
    name: "city-id.html___de",
    path: "/de/:city-:id(\\d+[dn]).html",
    meta: _91city_93_45_91id_93_46htmldEuq7gvUfmMeta || {},
    component: () => import("/app/pages/[city]-[id].html.vue").then(m => m.default || m)
  },
  {
    name: "city-id.html___it",
    path: "/it/:city-:id(\\d+[dn]).html",
    meta: _91city_93_45_91id_93_46htmldEuq7gvUfmMeta || {},
    component: () => import("/app/pages/[city]-[id].html.vue").then(m => m.default || m)
  },
  {
    name: "city-id.html___fr",
    path: "/fr/:city-:id(\\d+[dn]).html",
    meta: _91city_93_45_91id_93_46htmldEuq7gvUfmMeta || {},
    component: () => import("/app/pages/[city]-[id].html.vue").then(m => m.default || m)
  },
  {
    name: "hotel-id.html___es",
    path: "/es/:hotel-:id(\\d+).html",
    meta: _91hotel_93_45_91id_93_46htmlkUnRIcBpgmMeta || {},
    component: () => import("/app/pages/[hotel]-[id].html.vue").then(m => m.default || m)
  },
  {
    name: "hotel-id.html___en",
    path: "/en/:hotel-:id(\\d+).html",
    meta: _91hotel_93_45_91id_93_46htmlkUnRIcBpgmMeta || {},
    component: () => import("/app/pages/[hotel]-[id].html.vue").then(m => m.default || m)
  },
  {
    name: "hotel-id.html___de",
    path: "/de/:hotel-:id(\\d+).html",
    meta: _91hotel_93_45_91id_93_46htmlkUnRIcBpgmMeta || {},
    component: () => import("/app/pages/[hotel]-[id].html.vue").then(m => m.default || m)
  },
  {
    name: "hotel-id.html___it",
    path: "/it/:hotel-:id(\\d+).html",
    meta: _91hotel_93_45_91id_93_46htmlkUnRIcBpgmMeta || {},
    component: () => import("/app/pages/[hotel]-[id].html.vue").then(m => m.default || m)
  },
  {
    name: "hotel-id.html___fr",
    path: "/fr/:hotel-:id(\\d+).html",
    meta: _91hotel_93_45_91id_93_46htmlkUnRIcBpgmMeta || {},
    component: () => import("/app/pages/[hotel]-[id].html.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/search",
    meta: searchfcnTL5fl5XMeta || {},
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: searchfcnTL5fl5XMeta || {},
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/search",
    meta: searchfcnTL5fl5XMeta || {},
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___it",
    path: "/it/search",
    meta: searchfcnTL5fl5XMeta || {},
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___fr",
    path: "/fr/search",
    meta: searchfcnTL5fl5XMeta || {},
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/it-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr-sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]